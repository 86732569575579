<template>
  <v-row justify="end">
    <v-col cols="12" sm="6" md="4" lg="3">
      <v-select
        outlined
        dense
        :color="defaultColor ? defaultColor : 'textPrimary'"
        :item-color="defaultColor ? defaultColor : 'textPrimary'"
        placeholder="Filtrar data"
        hide-details
        prepend-inner-icon="fas fa-calendar-alt"
        v-model="selectedDate"
        :items="dateList"
        item-disabled="disabled"
      />
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="3" v-if="selectedDate === 'custom'">
      <v-menu max-width="350px" v-model="menu" ref="menu" transition="scale-transition" offset-y :close-on-content-click="false" :return-value.sync="dates">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            readonly
            outlined
            dense
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker width="auto" range v-model="dates" no-title :color="defaultColor ? defaultColor : 'primary'" :allowed-dates="allowedDates">
          <v-row class="mx-2">
            <v-col class="pa-0">
              <v-autocomplete
                v-model="dateType"
                :items="dateTypes"
                item-text="text"
                item-value="value"
                :color="defaultColor ? defaultColor : 'primary'"
                outlined
                multiple
                clearable
                dense
                :disabled="tab === 'fileMovements'"
              />
            </v-col>
            <v-col cols="12" align="end">
              <v-btn text :color="defaultColor ? defaultColor : 'primary'" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text :color="defaultColor ? defaultColor : 'primary'" @click="handlerDate(dates)">
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="3">
      <v-btn outlined block text class="white--text" height="39px" :color="defaultColor ? defaultColor : 'primary'" @click="handlerClearFilters">
        Limpar Filtros
      </v-btn>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="3">
      <v-btn block class="white--text" height="39px" :color="defaultColor ? defaultColor : 'textPrimary'" @click="handlerFilter()" :disabled="disabledSearch">
        Pesquisar
      </v-btn>
    </v-col>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-row>
</template>

<script>
import moment from 'moment';
import UserUtils from '@/shared/utils/user-utils';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default {
  name: 'DatePickerCustom',

  components: { SnackbarCustomize },

  data: () => ({
    menu: false,
    disabledSearch: false,
    today: null,
    dates: [],
    selectedDate: 'lastThirtyDays',
    dateTypes: [],
    dateType: ['createdAt'],
    dateList: [
      {
        text: 'Últimos 7 dias',
        value: 'lastSevenDays',
        disabled: false,
      },
      {
        text: 'Últimos 30 dias',
        value: 'lastThirtyDays',
        disabled: false,
      },
      {
        text: 'Últimos 3 meses',
        value: 'lastThreeMonths',
        disabled: false,
      },
      {
        text: 'Mês Atual',
        value: 'currentMonth',
        disabled: false,
      },
      {
        text: 'Personalizado',
        value: 'custom',
        disabled: false,
      },
      {
        text: 'Nenhuma Seleção de Data',
        value: 'noDate',
        disabled: false,
      },
      {
        text: 'Personalizado',
        value: 'custom',
        disabled: false,
      },
    ],
    selectedTab: '',
  }),

  mixins: [
    VerifyRoutesMixin,
  ],

  props: {
    tab: String,
    filtersForCheckDate: Object,
    defaultColor: {
      type: String,
      default: ""
    },
  },

  watch: {
    selectedDate: {
      immediate: true,
      deep: true,
      handler(newValue) {
        const dateValue = newValue || 'lastThirtyDays';
        this.setDateFilter(dateValue);
        this.checkSelectedDate();
      },
    },
    tab: {
      immediate: true,
      handler(value) {
        this.selectedTab = value;
        this.updateDateTypes();
        this.verifySessionStorage();
        this.setDateFilter();
        this.reloadDateList();
      },
    },
  },

  computed: {
    dateRangeText() {
      let text = '-';
      if (this.dates && this.dates.length > 0) {
        const sortedDates = this.dates.slice().sort((a, b) => new Date(a) - new Date(b));

        const formattedStartDate = moment(sortedDates[0]).format('DD/MM/YYYY');
        const formattedEndDate = moment(sortedDates[1]).format('DD/MM/YYYY');
        text = `${formattedStartDate} ~ ${formattedEndDate}`;
      }

      return text;
    },
  },

  created() {
    this.userUtils = new UserUtils();
  },

  methods: {
    verifySessionStorage() {
      if (sessionStorage.getItem('searchParams')) {
        const session = JSON.parse(sessionStorage.getItem('searchParams'));

        if (session.tab === 'movements') {
          this.dateType = session.dateType ? session.dateType : ['isMovementDate'];
          this.dates[0] = session.receivingDateStart;
          this.dates[1] = session.receivingDateEnd;
        } else {
          this.dateType = session.dateType ? session.dateType : ['createdAt'];
        }

        this.selectedDate = session.selectedDate;
      }
    },
    updateDateTypes() {
      this.dateTypes = [];
      switch(this.selectedTab) {
        case 'fileMovements':
          this.addDateType('Data de Criação', 'createdAt');
          this.dateType = ['createdAt'];
          break;

        case 'shippingBatches':
          this.addDateType('Data de Criação', 'createdAt');
          this.addDateType('Data de Envio', 'sendAt');
          this.dateType = ['createdAt'];
          break;

        case 'dataLoad':
          this.addDateType('Data de Criação', 'createdAt');
          this.dateType = ['createdAt'];
          break;

        default:
          this.addDateType('Data da movimentação', 'isMovementDate');
          this.addDateType('Data de envio para a operadora', 'isCarrierSendDate');
          this.addDateType('Data de finalização na operadora', 'isCarrierFinishedDate');
          this.addDateType('Data de início / fim de vigência', 'isValidityDate');
          this.addDateType('Data de início / fim efetivo', 'isSendDate');
          this.dateType = ['isMovementDate'];
          break;
      }
    },
    addDateType(text, value) {
      this.dateTypes.push({
        text,
        value,
      });
    },
    handlerDate(dates) {
      this.menu = false;
      const dateFormated = dates;

      if (dateFormated[0] > dateFormated[1]) {
        [dateFormated[0], dateFormated[1]] = [dateFormated[1], dateFormated[0]];
      }

      this.$refs.menu.save(dateFormated);
      this.setDataCustom(dateFormated);
    },
    allowedDates(date) {
      const selectedDate = moment(date);

      if (typeof this.dates === 'undefined' || this.dates === null) {
        this.dates = [];
      }

      if (this.dates.length === 0) {
        return true;
      }

      const startDate = moment(this.dates[0]);
      const minDate = startDate.clone().subtract(365, 'days');
      const currentDate = moment();

      return (selectedDate.isBetween(minDate, startDate.add(365, 'days'), 'day', '[]') && selectedDate.isSameOrBefore(currentDate, 'day'));
    },
    setDateFilter(value) {
      const today = moment();
      const dateRanges = {
        lastSevenDays: 6,
        lastThirtyDays: 29,
        lastThreeMonths: 89,
        currentMonth: today.clone().startOf('day').diff(today.clone().startOf('month').startOf('day'), 'days'),
        noDate: 'noDate',
      };

      let startDate;
      if (value === 'noDate') {
        this.dates = [];
        startDate = [];
      } else if (dateRanges[value] !== undefined) {
        startDate = today.clone().subtract(dateRanges[value], 'days');
        this.dates = [startDate.format('YYYY-MM-DD'), today.format('YYYY-MM-DD')];
      }

      sessionStorage.setItem('selectedDate', this.selectedDate);
      this.setDataCustom(this.dates);
    },
    handlerFilter() {
      if (this.validateObject()) {
        if (this.dateType.length !== 0 || this.selectedDate === 'noDate') {
          let { dates } = this;
          if (this.selectedDate === 'noDate') {
            dates = [];
          } else {
            this.setDateFilter(this.selectedDate)
            dates = this.dates
          }
          const { dateType } = this;
          sessionStorage.removeItem('searchParams');
          this.$emit('handlerClickFilter', dates, dateType);
        } else {
          this.menu = true;
          this.$refs.SnackbarCustomize.open('error', 'Selecione um tipo de data para prosseguir');
        }
      }
    },
    setDataCustom(dates) {
      if (this.selectedDate === 'custom') {
        const cleanedDates = dates.map((date) => {
          if (date.includes('T00:00:00.000')) {
            // eslint-disable-next-line no-param-reassign
            date = date.replaceAll('T00:00:00.000', '');
          }
          if (date.includes('T23:59:59.999')) {
            // eslint-disable-next-line no-param-reassign
            date = date.replaceAll('T23:59:59.999', '');
          }
          return date;
        });
        const datesSearch = {
          dateType: this.dateType,
          dates: cleanedDates,
        };
        sessionStorage.setItem('datesSearch', JSON.stringify(datesSearch));
      } else {
        sessionStorage.removeItem('datesSearch');
      }
    },
    handlerClearFilters() {
      sessionStorage.removeItem('searchParams');
      if (this.tab === 'fileMovements') {
        this.selectedDate = 'noDate';
        this.dateType = ['createdAt'];
        this.dates = [];
      } else if (this.tab === 'shippingBatches') {
        this.dateType = ['createdAt'];
        this.selectedDate = 'lastThirtyDays';
      } else {
        this.selectedDate = 'noDate';
        this.dateType = ['isMovementDate'];
        this.dates = [];
      }

      this.$emit('clearFilters');
    },
    getFilterByFavorite(param) {
      this.dateType = param.dateType;
      this.$nextTick(() => {
        this.selectedDate = param.selectedDate;
        if (param.selectedDate === 'custom') {
          this.dates[0] = param.receivingDateStart || param.createdAtStart || param.sendAtStart;
          this.dates[1] = param.receivingDateEnd || param.createdAtEnd || param.sendAtEnd;
        }
      });

      setTimeout(() => {
        this.handlerFilter();
      }, 200);
    },
    reloadDateList() {
      const isShippingBatches = this.selectedTab === 'shippingBatches';

      this.dateList = this.dateList.map((element) => ({ ...element, disabled: isShippingBatches && element.value === 'noDate' }));

      this.selectedDate = isShippingBatches ? 'lastThirtyDays' : this.selectedDate;
    },
    checkSelectedDate() {
      if (this.selectedDate === 'noDate') {
        this.$emit('openExpandedContent', 0);
      }
    },
    validateObject() {
      if (this.selectedDate === 'noDate') {
        const {
          protocolNumber, movementRecordId, movementInsuranceCarrierId, financialGroup, financialGroupId, contract, contractId, subContract, subContractId, insuranceCarrierIds, movementType, status, document, name, reportLayout, id, subject
        } = this.filtersForCheckDate;

        const showMessageAndReturnFalse = (message) => {
          this.$refs.SnackbarCustomize.open('error', message);
          return false;
        };

        if (this.areAllMandatoryFieldsEmpty(
          id, protocolNumber, movementRecordId, movementInsuranceCarrierId,
          financialGroup, financialGroupId, contract, contractId, subContract,
          subContractId, insuranceCarrierIds, movementType, status, document,
          name, reportLayout, subject)
        ) {
          return showMessageAndReturnFalse('Preencha pelo menos um campo para prosseguir com a pesquisa ou selecione uma data específica');
        }

        if (this.areSpecificConditionsMet(
          id, protocolNumber, movementRecordId, movementInsuranceCarrierId,
          financialGroup, financialGroupId, contract, contractId, subContract,
          subContractId, insuranceCarrierIds, movementType, status, document,
          name, reportLayout, subject)
        ) {
          return showMessageAndReturnFalse('Adicione um outro filtro de pesquisa para prosseguir ou selecione uma data específica');
        }

        if (this.areAdditionalConditionsMet(
          id, protocolNumber, movementRecordId, movementInsuranceCarrierId,
          financialGroup, financialGroupId, contract, contractId, subContract,
          subContractId, insuranceCarrierIds, movementType, status, document,
          name, reportLayout, subject)
        ) {
          return showMessageAndReturnFalse('Adicione um outro filtro de pesquisa para prosseguir ou selecione uma data específica');
        }

        return true;
      }

      return true;
    },
    areAllMandatoryFieldsEmpty(...fields) {
      return fields.every((field) => this.isEmpty(field));
    },
    areSpecificConditionsMet(id, protocolNumber, movementRecordId, movementInsuranceCarrierId, financialGroup, financialGroupId, contract, contractId, subContract, subContractId, insuranceCarrierIds, movementType, status, document, name, reportLayout, subject) {
      return (
        this.isEmpty(id)
        && this.isEmpty(protocolNumber)
        && this.isEmpty(movementRecordId)
        && this.isEmpty(movementInsuranceCarrierId)
        && this.isEmpty(financialGroup)
        && this.isEmpty(financialGroupId)
        && this.isEmpty(contract)
        && this.isEmpty(contractId)
        && this.isEmpty(subContract)
        && this.isEmpty(subContractId)
        && this.isEmpty(document)
        && this.isEmpty(name)
        && this.isEmpty(reportLayout)
        && this.isEmpty(subject)
        && (!insuranceCarrierIds || insuranceCarrierIds.length === 0)
        && (movementType && movementType.length > 0)
        && status && status.length === 0
      );
    },
    areAdditionalConditionsMet(id, protocolNumber, movementRecordId, movementInsuranceCarrierId, financialGroup, financialGroupId, contract, contractId, subContract, subContractId, insuranceCarrierIds, movementType, status, document, name, reportLayout, subject) {
      return (
        this.isEmpty(id)
        && this.isEmpty(protocolNumber)
        && this.isEmpty(movementRecordId)
        && this.isEmpty(movementInsuranceCarrierId)
        && this.isEmpty(financialGroup)
        && this.isEmpty(financialGroupId)
        && this.isEmpty(contract)
        && this.isEmpty(contractId)
        && this.isEmpty(subContract)
        && this.isEmpty(subContractId)
        && this.isEmpty(document)
        && this.isEmpty(name)
        && this.isEmpty(reportLayout)
        && this.isEmpty(subject)
        && (!insuranceCarrierIds || insuranceCarrierIds.length === 0)
        && (!movementType || movementType.length === 0)
        && (status && (status.length === 0 || status.includes('SUCCESS') || status.includes('CANCELED')))
        && this.isEmpty(document)
        && this.isEmpty(name)
      );
    },
    isEmpty(value) {
      return value === '' || value === undefined;
    },
    setNoDate() {
      this.selectedDate = 'noDate';
    },
  },
};
</script>
