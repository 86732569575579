<template>
  <v-dialog
    v-model="modal"
    max-width="750">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg">
        <v-toolbar
          width="100%"
          color="wine"
          height="79"
          style="position: relative;">
          <v-row
            class="text-center d-flex justify-center">
            <span
              class="text-h5 white--text font-weight-bold">
              Geração de conferência
            </span>
            <v-btn
              icon
              class="white elevation-4"
              color="wine"
              heiht="43"
              max-height="43"
              width="43"
              style="position: absolute; right: 5%; top: 20%;"
              @click="close">
              <v-icon
                size="30">
                close
              </v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text
          class="mt-1"
          style="height: 200px;">
          <v-container
            fuild>
            <v-form
              ref="ConferenceModalForm">
              <v-row>
                <v-col
                  cols="12"
                  class="text-center">
                  <p
                    class="text-h6 wineLabel--text font-weight-bold">
                    Geração de conferência do arquivo nº {{ fileId }}
                  </p>
                  <p
                    class="text-subtitle-1 wineLabel--text font-weight-regular pb-0 mb-0">
                    Defina os campos abaixo conforme a necessidade.
                  </p>
                </v-col>
                <v-col
                  cols="6">
                  <label
                    class="text-body-1 wineLabel--text">
                    Data de Fechamento de Fatura*
                  </label>
                  <v-menu
                    ref="menuIssueDate"
                    v-model="menuIssueDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto">
                    <template
                      v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="issueDate"
                        prepend-inner-icon="mdi-calendar"
                        outlined
                        class="rounded-pill"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[rule.required]"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      locale="pt-br"
                      color="wine"
                      no-title
                      scrollable>
                      <v-spacer />
                    <v-btn
                      text
                      @click="cleanIssueDate">
                      LIMPAR
                    </v-btn>
                    <v-btn
                      text
                      @click="saveIssueDate">
                      OK
                    </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                cols="6">
                  <label
                    class="text-body-1 wineLabel--text">
                    Data de Corte*
                  </label>
                  <v-menu
                    ref="menuCutOffDate"
                    v-model="menuCutOffDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto">
                    <template
                      v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="cutOffDate"
                        prepend-inner-icon="mdi-calendar"
                        outlined
                        class="rounded-pill"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[rule.required]"
                      />
                    </template>
                    <v-date-picker
                      v-model="dates"
                      locale="pt-br"
                      color="wine"
                      no-title
                      scrollable
                      range>
                      <v-spacer />
                    <v-btn
                      text
                      @click="cleanCutOffDate">
                      LIMPAR
                    </v-btn>
                    <v-btn
                      text
                      @click="saveCutOffDate">
                      OK
                    </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions
          class="pa-13">
          <v-row>
            <v-spacer />
            <v-btn
              height="50"
              width="150"
              color="blue-grey lighten-5"
              class="rounded-pill mr-6"
              @click="close">
              <v-icon
                color="wine">
                mdi mdi-arrow-left
              </v-icon>
              <span
                class="wine--text text-h6">
                Voltar
              </span>
            </v-btn>
            <v-btn
              height="50"
              width="260"
              color="wine"
              class="rounded-pill"
              @click="generateConference"
              :loading="loading">
              <span
                class="white--text text-h6">
                Gerar conferência
              </span>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
      <SnackbarCustomize ref="SnackbarCustomize" />
    </template>
  </v-dialog>
</template>
<script>
import moment from "moment";
import Rules from '@/shared/validators/formRules';
import DataLoadService from "@/services-http/sdi/DataLoadService.js";
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";

export default {
  name: "ConferenceModal",

  components: {
    SnackbarCustomize
  },

  data: () => ({
    moment: moment,
    dataLoadService: null,
    modal: false,
    loading: false,
    menuCutOffDate: false,
    menuIssueDate: false,
    rule: null,
    days: Array.from({ length: 31 }, (_, i) => i + 1),
    fileId: null,
    dates: [],
    date: null,
    issueDate: '',
    cutOffDate: '',
  }),

  watch: {
    date: {
      deep: true,
      handler(value) {
        if (value) this.saveIssueDate();
      },
    },
  },

  methods: {
    open(fileId) {
      this.modal = true;
      this.fileId = fileId;
    },
    close() {
      this.$refs.ConferenceModalForm.resetValidation();
      this.issueDate = '';
      this.cutOffDate = '';
      this.dates = [];
      this.date = null;
      this.modal = false;
    },
    saveIssueDate() {
      if (this.date) {
        const issueDate = moment(this.date, 'YYYY-MM-DD').format('DD/MM/YYYY');

        this.issueDate = issueDate;
        this.menuIssueDate = false;
      }
    },
    cleanIssueDate() {
      this.issueDate = null;
      this.date = null;
    },
    saveCutOffDate() {
      if (this.dates.length === 2) {
        this.sortDates();

        if (!this.validateDateRange(this.dates)) return;

        const startDate = moment(this.dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY');
        const endDate = moment(this.dates[1], 'YYYY-MM-DD').format('DD/MM/YYYY');

        this.cutOffDate = `${startDate} à ${endDate}`;
        this.menuCutOffDate = false;
      } else {
        this.$refs.SnackbarCustomize.open('error', 'Defina um período para entre datas a Data de Corte.');
      }
    },
    sortDates() {
      this.dates.sort((a, b) => new Date(a) - new Date(b));
    },
    validateDateRange(dates) {
      const [start, end] = dates;
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 60) {
        this.$refs.SnackbarCustomize.open('error', 'Defina um período máximo de 60 dias para a Data de Corte.');
        return false;
      }
      return true;
    },
    cleanCutOffDate() {
      this.cutOffDate = null;
      this.dates = null;
    },
    async generateConference() {
      if (!this.$refs.ConferenceModalForm.validate()) return;

      this.loading = true;

      const params = {
        fileId: this.fileId,
        issueDate: this.date.toString(),
        cutOffStartDate: this.dates[0].toString(),
        cutOffEndDate: this.dates[1].toString(),
      };

			await this.dataLoadService.CreateCarrierInvoiceValidation(params).then(() => {
        this.$refs.SnackbarCustomize.open('success', 'Geração de conferência realizada.');
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro na geração de conferência.');
      }).finally(() => {
        this.loading = false;
        this.close();
      });
		},
  },
  created() {
    this.dataLoadService = new DataLoadService();
    this.rule = new Rules();
  },
};
</script>
